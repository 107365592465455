.main {
    padding-top: 2em;
    padding-bottom: 0;
    display: flex;
    width: 100%;
    max-width: 1200px;

    @include breakpoint(XS) {
        flex-direction: column;
    }
}

.main-right {
    text-align: center;
    padding: 0 1em;

    img {
        float: right;
        width: 65%;
        height: auto;

        @include breakpoint(L) {
            width: 65%;
        }

        @include breakpoint(S) {
            width: 100%;
        }

        @include breakpoint(XS) {
            width: 50%;
            margin: 0 auto;
            float: none;
        }
    }
}

.main-left {
    padding-top: 2em;
    padding-right: 2em;

    h1 {
        text-align: center;
        font-size: 1.5em;
        font-weight: 400;

        @include breakpoint(M) {
            font-size: 1.25em;
        }
        @include breakpoint(XS) {
            font-size: 1em;
        }
        span {
            font-size: 1.675em;
        }
    }

    .app-instructions,
    .app-disclaimer{
        
        @include breakpoint(XS) {
            display: none;
        }
    }

    .button__getApp {
        display: none;

        @include breakpoint(XS) {
            display: block;
        }
    }
}

.main-left,
.main-right {
    width: 50%;
}

.main-left {
    @include breakpoint(ipad-portrait) {
        width: 65%;
    }
    @include breakpoint(XS) {
        width: 100%;
        padding: 1em;
    }
}

.main-right {
    @include breakpoint(ipad-portrait) {
        width: 35%;

        img {
            width: 100%;
        }
    }
    @include breakpoint(XS) {
        width: 100%;
        padding: 1em;
    }
}

.form-wrapper {
    padding: 3em;
    background: #fff;
    margin-bottom: 2em;

    @include breakpoint(XS) {
        padding: 2em;
    }
}

.icon-list {
    margin-bottom: 2em;

    .icon-single {
        display: flex;
        align-items: center;
        margin-bottom: 0.9em;

        @include breakpoint(XS) {
            margin-bottom: 1.3em;
        }        

        p {
            margin: 0;
            font-size: 0.875em;

            @include breakpoint(XS) {
                font-size: 1em;
            }
        }
        .icon {
            width: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.5em;

            svg {
                width: 1.75em;
                height: 1.75em;
            }
        }
    }
}

.text-app {
    @include breakpoint(XS) {
        display: none;
    }
}

.app-links {
    display: none;

    @include breakpoint(XS) {
        display: block;
    }

    a {
        width: 100%!important;
        text-align: center;
        margin: 5px 0;
    }
}

.phone-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $color-green;
    margin-bottom: 10px;

       .styled-select {
           min-width: 35px;
           height: 40px;
           overflow: hidden;
           background: url(../images/arrow-down-phone.svg) no-repeat right 17px;
           display: inline-block;
           position: relative;
           z-index: 999;

            select {
                width: inherit;
                padding-right: 12px;
                appearance: none;
                background: transparent;
                border: 0;
                color: $color-body;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0;
                height: 20px;
                position: absolute;
                left: 0;
                top: 10px;

            }

            select::-ms-expand{
                display:none;
              }

            option {
                color: #000;
            }
       }

    input {
        border: none;
        margin-bottom: 0;
    }
}

.app-disclaimer {
    font-size: 0.75em;
    margin-bottom: 0;
}

.page-template-page-form-initials {
    .gform_wrapper {
        ul {
            margin-bottom: 1em;
        }
        .gform_footer,
        .gfield_label {
            display: none;
        }
        li {
            margin: 0;
            padding: 0;

            &:before {
                display: none;
            }
            input {
                margin: 0;
            }
        }
    }
    @include breakpoint(XS) {
        .text-app,
        .app-disclaimer {
            display: block;
        }
        .app-links {
            display: none;
        }
    }
}