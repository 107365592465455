//   * CSS Animations are Defined here – feel free to use them as appropriate!
//     This uses Compass mixins to handle all the vendor prefixes – when you want to use them in your CSS, use the following syntax below:
//
//     .item{
//     @include animation(YOUR-ANIMATION-HERE TIME INFINITE-OR-ONCE-ONLY EASING);
//     @include transform-origin(50% 50%);
//     }



// *** 1. Basic Fades *** //

@keyframes(fade-in) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes(fade-out) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes(fade-in-left) {
  0% {
    opacity: 0;
    transform: translateX(-2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes(fade-in-right) {
  0% {
    opacity: 0;
    transform: translateX(2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes(fade-in-up) {
  0% {
    opacity: 0;
    transform: translateY(2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes(fade-in-down) {
  0% {
    opacity: 0;
    transform: translateY(2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes bggradient {
  0%{background-position:0% 2%}
  50%{background-position:100% 99%}
  100%{background-position:0% 2%}
}
@-moz-keyframes bggradient {
  0%{background-position:0% 2%}
  50%{background-position:100% 99%}
  100%{background-position:0% 2%}
}
@keyframes bggradient { 
  0%{background-position:0% 2%}
  50%{background-position:100% 99%}
  100%{background-position:0% 2%}
} 