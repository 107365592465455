footer {
    p {
        font-size: 0.75em;

        a {
            font-weight: normal;

            &:hover {
                text-decoration: underline;
            }
        }
        span {
            margin: 0 1em;
        }
    }
}